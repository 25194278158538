import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Favour
			</title>
			<meta name={"description"} content={"Ви шукаєте ідеальне місце, щоб побалувати ваш улюблений вид спорту?Не дивись далі!Наша служба прокату футбольного суду, аромат гри, представляє ідеальне місце для задоволення прекрасної гри."} />
			<meta property={"og:title"} content={"Головна | Favour"} />
			<meta property={"og:description"} content={"Ви шукаєте ідеальне місце, щоб побалувати ваш улюблений вид спорту?Не дивись далі!Наша служба прокату футбольного суду, аромат гри, представляє ідеальне місце для задоволення прекрасної гри."} />
			<link rel={"shortcut icon"} href={"https://ua.skyifair.com/img/456236236.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.skyifair.com/img/456236236.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.skyifair.com/img/456236236.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.skyifair.com/img/456236236.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.skyifair.com/img/456236236.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.skyifair.com/img/456236236.jpeg"} />
			
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-7">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="120px 50px 120px 50px"
					lg-width="100%"
					lg-padding="80px 50px 90px 50px"
					sm-padding="80px 25px 90px 25px"
					lg-display="flex"
					lg-flex-direction="column"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Favour
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
						Ви шукаєте ідеальне місце для улюбленого виду спорту? Наша служба прокату футбольного спорядження, Favour, представляє ідеальне місце для задоволення прекрасної гри. Незалежно від того, що ви організовуєте дружній матч з друзями чи потребуєте місця для навчальних занять, у нас є все необхідне.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 0 0px 0px"
							padding="12px 28px 12px 28px"
							background="rgba(0, 119, 204, 0)"
							color="--darkL1"
							font="normal 400 17px/1.5 --fontFamily-sans"
							border-width="1px"
							border-radius="5px"
							href="/contact-us"
							type="link"
							text-decoration-line="initial"
							border-color="--color-darkL1"
							border-style="solid"
							hover-color="--light"
							hover-background="--color-darkL1"
						>
							Виберіть локацію
						</Button>
					</Box>
				</Box>
				<Image
					src="https://ua.skyifair.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
		<Section padding="80px 0 0px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="30%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="normal 900 48px/1.2 --fontFamily-sans"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Про нас
				</Text>
			</Box>
			<Box
				display="flex"
				width="70%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					В Favour ми ділимося глибокою пристрастю до футболу і присвячені наданні преміум -досвіду.Наші заклади майстерно розроблені для задоволення потреб любителів футболу на кожному рівні майстерності, від випадкових гравців до починаючих професіоналів.Ось що нас відрізняє:
				</Text>
			</Box>
		</Section>
		<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
		<Section padding="0px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="30%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Місце
				</Text>
			</Box>
			<Box
				display="flex"
				width="70%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Ми маємо численні місця, що охоплюють різні міста та країни, гарантуючи, що ви можете легко знайти футбольний суд поблизу.Наша непохитна прихильність до надання доступних закладів гарантує, що ви можете насолоджуватися спортом, який ви обожнюєте, незалежно від того, де ви опинитесь.
				</Text>
			</Box>
		</Section>
		<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-5">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 36px 00px 0px"
					color="--darkL2"
					font="--lead"
					lg-text-align="center"
					width="30%"
					lg-width="100%"
					lg-margin="24px 0px 30px 0px"
					lg-order="1"
					md-text-align="left"
				>
					Ми цінуємо ваш вибір прихильності як ваше остаточне місце для футбольного хвилювання. Ми з нетерпінням очікуємо, що вітаємо вас на наших судах і допомагаємо вам створити незабутні моменти на полі!
				</Text>
				<Box
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 25% 0px"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
					lg-padding="0px 0px 50% 0px"
					lg-width="100%"
					width="55%"
					height="auto"
				>
					<Image
						src="https://ua.skyifair.com/img/3.jpg"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						object-position="0% 0%"
					/>
				</Box>
			</Box>
			<Box
				justify-content="center"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="45%"
				lg-width="100%"
				align-items="flex-start"
			>
				<Box
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					overflow-y="hidden"
					position="relative"
					padding="0px 0px 150% 0px"
					lg-padding="0px 0px 100% 0px"
				>
					<Image
						object-fit="cover"
						display="block"
						top={0}
						lg-right="0px"
						lg-width="100%"
						bottom={0}
						min-height="100%"
						lg-left="-1px"
						src="https://ua.skyifair.com/img/2.jpg"
						position="absolute"
						width="100%"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="55%"
				lg-order="1"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 40% 0px"
				>
					<Image
						src="https://ua.skyifair.com/img/4.jpg"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
					/>
				</Box>
				<Text
					margin="30px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Зв'яжіться з нами
				</Text>
				<Text
					margin="0px 0px 20px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Чи є у вас запити чи потрібна допомога? Наша доступна та добре обізнана команда легко доступна для надання допомоги. Не соромтеся зв’язатися з нами електронною поштою, телефоном або нашою контактною формою, і ми своєчасно відповімо вам.
				</Text>
				<Components.ButtonContact />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6530dc90e2dd3a0022211ece"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});